export const ru = {
    /*app*/
    sent: 'Отзыв отправлен',
    required_field: 'Обязательное поле',
    input_max_length: 'Превышено кол-во символов',
    incorrect_format_photo: 'Неверный формат фото',
    next: 'Далее',
    phone_number: 'Ваш номер телефона',
    your_name: 'Ваше имя',
    invalid_phone: 'Неверный формат телефона',
    close: 'Закрыть',
    cancel: 'Отменить',
    yes: 'Да',
    no: 'Нет',
    minutes: 'мин',

    /*errors*/
    invalid_qr_title: 'QR-код не действителен',
    invalid_qr_text: 'Попробуйте повторно отсканировать QR-код',
    invalid_web_title: 'Ссылка не действительна',
    invalid_web_text: '',
    token_expired_title: 'Время действия сессии истекло',
    token_expired_text: 'Отсканируйте QR-код',
    token_web_expired_title: 'Время действия сессии истекло',
    token_web_expired_text: '',
    forbidden_title: 'Доступ ограничен',
    forbidden_text: 'Пожалуйста, попробуйте позже',
    service_not_working_title: 'Сервис временно не доступен',
    service_not_working_text: 'Пожалуйста, попробуйте позже',
    oops: 'Упс!',
    mobile_only_alert: 'К сожалению, эта страница работает только на мобильных и планшетных устройствах',
    network_error: 'Ошибка сети. Пожалуйста, попробуйте еще раз',
    use_max_characters: 'Используйте до {number} символов',
    service_not_configure_title: 'Сервис не настроен',
    service_not_configure_text: 'Пожалуйста, попробуйте позже',
    token_expired_on_request: 'Упс! Похоже, что время действия сессии исчерпано. Пожалуйста, отсканируйте QR код снова.',

    /*home*/
    home_title: 'Выберите действие',
    home_menu: 'Посмотреть меню',
    home_call_buttons: 'Кнопки вызова',
    home_review: 'Оставить отзыв',
    your_menu_will_be_here: 'Тут будет ваше меню :)',

    /*call_buttons*/
    call_buttons_title: 'Выберите действие',
    call_buttons_success_popup_title: 'Спасибо!',
    call_buttons_success_popup_text: 'Мы получили ваше пожелание ☺',

    /*review_module*/
    review_title: 'Общее впечатление',
    review_your_rating: 'Ваша оценка*',
    review_rating_1: 'Очень плохо 😡',
    review_rating_2: 'Плохо 😠',
    review_rating_3: 'Средне 😐',
    review_rating_4: 'Хорошо 🙂',
    review_rating_5: 'Отлично 🤩',
    review_message_label: 'Комментарий*',
    review_send_button: 'Отправить',
    review_photo_popup_title: 'Фото к отзыву',
    review_photo_popup_subtitle: 'Вы можете добавить фото к вашему отзыву',
    review_photo_popup_add_photo_button: 'Добавить фото',
    review_success_title: 'Спасибо 🙂',
    review_success_text: 'Мы получили ваш отзыв',
    review_success_new_review_button: 'Оставить новый отзыв',
    review_contact_info_title: 'Спасибо',
    review_contact_info_text:
        'Пожалуйста, оставьте свой номер телефона, чтобы мы могли связаться с вами в случае необходимости',
    review_change_review_button: 'Изменить отзыв',

    //menu
    add_to_cart: 'Добавить к заказу',
    go_to_cart: 'К заказу',
    go_to_menu: 'Вернуться к меню',
    position_was_added_to_cart: 'Позиция добавлена к заказу',
    position_was_remove_from_cart: 'Позиция удалена из заказа',
    from_price: 'от',
    please_choose_modification: 'Пожалуйста, выберите товар из списка',

    //currency
    USD: 'дол',
    EUR: 'евро',
    UAH: 'грн',
    RUB: 'руб',

    //cart
    cart_title: 'Оформление заказа',
    cart_title_static: 'Список сохраненных позиций',
    cart_text_static: 'Позовите официанта, если готовы сделать заказ',
    cart_order_label: 'Ваш заказ',
    cart_total_text: 'Всего к оплате',
    cart_delivery_label: 'Тип заказа',
    cart_payment_label: 'Варианты оплаты',
    cart_comment_label: 'Комментарий к заказу',
    cart_checkout_button: 'Оформить заказ',
    cart_delete_title: 'Удаление позиции',
    cart_delete_text: 'Хотите удалить эту позицию из заказа?',
    approve: 'Подтвердить',
    IN_HOUSE: 'В заведении',
    TAKE_AWAY: 'С собой',
    CASH: 'Оплата наличными',
    CARD: 'Оплата картой',
    cart_success_title: 'Спасибо 🙂',
    cart_success_text: 'Мы получили ваш заказ',
    cart_back_button: 'На главную страницу',
    cart_clear_order: 'Очистить список',
    order_successfully_clear: 'Ваш список очищен'
};
